@media (max-width: 767px) {
  .col-sm-6 {
    width: 50% !important;
  }
  .mobile-flex-wrap {
    flex-wrap: wrap;
  }
  .reveal-from-bottom {
    margin: 5px !important;
    flex-basis: 30%;
  }
  h2 {
    font-size: 50px !important;
  }
  .m-f-14 {
    font-size: 14px !important;
  }
  @media screen and (max-width: 768px) {
    [data-aos] {
      pointer-events: auto !important;
    }
  
    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
      opacity: 1 !important;
    }
  
    html:not(.no-js) [data-aos=fade-up] {
      transform: none !important;
    }
  }
}
@media (min-width: 1100px) {
  .h-10rem {
    height: 11rem;
  }
  .md-hidden {
    visibility: hidden;
    display: none !important;
  }
}
@media (max-width: 1100px) {
  .top-margin {
    margin-top: 5rem !important;
  }
  .text-client {
    font-size: 15px !important;
  }
  .h-m5rem {
    height: 7rem;
  }
  .c-mobile {
    height: 25rem;
  }
  .h-35rem {
    height: 40rem;
  }
  .px-2rem {
    padding: 2rem 1rem 0 1rem !important;
  }
  .banner-herohome {
    background-size: 200vw;
}
  .mobile-hidden {
    display: none !important;
  }
  .mobile-justify-between {
    justify-content: space-between !important;
  }
  .mobile-justify-center {
    justify-content: center !important;
  }
  .mobile-flex-column {
    flex-direction: column !important;
  }
  .footerClass {
    padding: 2rem 4rem 4rem 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .footerClass {
    padding: 4rem 6rem 4rem 6rem !important;
  }
}
@media (min-width: 1536px) {
  .footerClass {
    padding: 4rem 10rem 4rem 10rem !important;
  }
}