@font-face {
  font-family: "Poppins";
  src: url("../assets/poppins-regular.woff") format("woff");
}
a {
  text-decoration: none !important;
}
html * {
  font-family: Poppins, sans-serif !important;
}
h3 {
  font-weight: 600;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0 !important;
}
.text-justify {
  text-align: justify !important;
}
.sp_services_wrapper {
  width: 100%;
  text-align: center !important;
  background-color: #f0f2f4;
}
.grey-wrap {
  background-color: #f0f2f4;
  text-align: center !important;
}
/* .banner-herohome {
  z-index: 1;
  background-image: url(../assets/slider_bg.png);
  background-repeat: no-repeat;
  background-position: center;
} */
.sp_choose_heading_main_wrapper {
  width: 100%;
  text-align: center !important;
}
img.Killen-Logo {
  width: 250px;
  height: 80px;
  flex-grow: 0;
  object-fit: contain;
}
.fill-current {
  fill: currentColor;
}
.w-6 {
  width: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.left-0 {
  left: 0px;
}
.z-20 {
  z-index: 20;
}
.px-2rem {
  padding: 0 2rem 0 2rem;
}
.-ml-16 {
  margin-left: -4rem;
}
.h-screen {
  height: 100vh;
}
.max-w-sm {
  max-width: 24rem;
}
.counter_name {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin: 7px 0 0;
}
.counter_number {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 0;
  line-height: 15px;
}
.clients_wrapper {
  background-color: white;
}
.w-33p {
  width: 33%;
}
.bg-yellow {
  background-color: #f1b757;
}
.bg-pink {
  background-color: #f680ab;
}
.bg-green {
  background-color: #1bdec6;
}
.text-client {
  color: #2f469f;
  font-weight: 600;
  font-size: 20px;
}
.h-3rem {
  height: 3rem;
}
.counter_icon {
  font-size: 48px;
  color: #fff;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1de7eb;
  background: linear-gradient(to right, #6984c2, #22bffb);
}
.w-10p {
  width: 10%;
}
.h-6 {
  height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.5;
}
.opacity-0 {
  opacity: 0;
  display: none;
}
.opacity-100 {
  opacity: 1;
}
.w-40 {
  width: 10rem;
}
.fixed {
  position: fixed;
}
.rideone {
  background-color: #6cc785;
  padding-top: 25px;
  border-radius: 8px 0px 0px 8px;
  text-align: center !important;
  height: 125px;
  margin-left: 15px;
}
.tt-item {
  padding: 2px 20px;
}
.bb-header {
  border-bottom: 1px solid #eaeaea;
}
.tt-btn {
  display: -ms-inline-flexbox;
  font-size: 14px;
  padding: 14px 10px 14px;
  color: #fff;
  cursor: pointer;
  background: #6984c2;
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: 600;
}
.button-service {
  border: 2px rgb(1, 151, 4) solid;
  background-color: rgb(1, 151, 4);
  padding: 2px 10px;
  color: white;
}
.top-margin {
  margin-top: 8rem;
}
.tt-header-text {
  color: #5f7b91;
}
.box-info {
  background-color: #fff;
  padding: 42px 30px 36px 40px;
}
.box-trust {
  background-color: #fff;
  padding: 12px 12px 6px 12px;
  height: 12rem;
}
.bg-youth-invention {
  background-color: #f3f1ed;
  padding-bottom: 0px !important;
}
.button-download {
  border: 2px rgb(0, 86, 235) solid;
  background-color: rgb(0, 86, 235);
  padding: 2px 10px;
  color: white;
}
.ridetwo {
  background-color: #9a75fe;
  padding-top: 30px;
  border-radius: 8px 0px 0px 8px;
  text-align: center !important;
  height: 125px;
  margin-left: 15px;
}

.ridethree {
  background-color: #4ebce5;
  padding-top: 35px;
  border-radius: 8px 0px 0px 8px;
  text-align: center !important;
  height: 125px;
  margin-left: 15px;
}
.inforide {
  box-shadow: 1px 2px 8px 0px #f1f1f1;
  background-color: white;
  border-radius: 8px;
  height: 125px;
}
.fontsty {
  margin-right: -15px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pst_bottompadder50 {
  padding-bottom: 50px;
}
.text-left {
  text-align: initial;
}
.team_btn {
  text-align: center !important;
  width: 100%;
}
.insects {
  background: #efefef;
  padding: 30px 0px 10px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.footerClass {
  padding: 4rem 10rem 4rem 10rem;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.bg-homeservice {
  background-image: url(../assets/bg-homeservice.jpg);
  background-size: cover;
  color: rgb(200 193 193);
}
.bg-dotted {
  background-image: url(../assets/footer-bg__bottom.png);
}
.bg-sanitize {
  background-color: #f0f2f4;
}
section {
  padding: 75px 0 30px;
}
.pr-3 {
  padding-right: 1.5rem;
}
.footer-10 {
  background: #505b69;
  padding: 0 0 4em 0;
}
.transformY50 {
  transform: translateY(-50%);
}
a {
  color: inherit;
  text-decoration: inherit;
}
.footer-heading {
  color: white;
  /* font-weight: 800; */
  font-size: 1rem;
  margin-bottom: 1rem;
}
.carousel-caption {
  background-color: #9993934f !important;
}
.bg-contact-map {
  background-color: #e0e5eb;
}
.footer-p {
  color: rgba(230, 242, 255, 0.6);
  font-size: 14px !important;
  line-height: 1.7rem;
  font-family: Gordita Regular;
}
.tem-btn-alt,
.tem-btn,
.single-blog-btn {
  font-weight: 600;
  display: inline-block;
  position: relative;
  z-index: 2;
  text-align: center !important;
  height: 50px;
  line-height: 45px;
  width: 175px;
  overflow: hidden;
  background: #3a89ff;
  color: #fff;
  border-radius: 45px;
  text-transform: capitalize;
  transition: all 0.5s ease;
}
.tem-btn-alt .btn-front,
.tem-btn .btn-front,
.single-blog-btn .btn-front {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: all 0.8s ease;
}
.tem-btn-alt .btn-back,
.tem-btn .btn-back,
.single-blog-btn .btn-back {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scale(4);
  transition: all 0.8s ease;
  opacity: 0;
}
.pst_btn_form li {
  float: right;
  list-style: none;
}
.team_btn ul {
  display: inline-block;
  float: none;
}
.tem-btn-alt:hover .btn-front,
.tem-btn:hover .btn-front {
  transform: scale(0);
  color: #3a89ff;
}
.tem-btn-alt:hover .btn-back,
.tem-btn:hover .btn-back {
  transform: scale(1);
  opacity: 1;
  color: #3a89ff;
}
.pst_btn_form li a:hover {
  background: transparent;
  border: 2px solid #3a89ff;
  color: white;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.whatsapp-button {
  position: fixed;
  bottom: 3%;
  border: none;
  right: 1%;
  z-index: 99;
  background-color: #25d366;
  border-radius: 50px;
  color: #ffffff;
  text-decoration: none;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1);
  animation: effect 5s infinite ease-in;
}
.pop-service {
  clip-path: ellipse(100% 50% at 2% 50%);
  position: fixed;
  top: 45%;
  background-color: #a01616;
  left: 0%;
  z-index: 99;
  width: 40px;
  display: flex;
  cursor: pointer;
  height: 200px;
  /* box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 1); */
  animation: effect 5s infinite ease-in;
}
.pop-service .pop-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  color: white;
  font-size: 18px;
}
.maindiv {
  /* border: 1px solid #cccccc; */
  padding: 20px;
  /* margin-top: 57px; */
  margin-bottom: 30px;
  position: relative;
  /* background: #fff; */
  /* height: 14rem; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.maindiv:hover:after,
.maindiv:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.maindiv:hover {
  border: 1px solid transparent;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.maindiv:hover .rotate {
  background: #3a89ff;
  border: 1px solid transparent;
  transform: rotateY(180deg) rotate(0deg);
  position: relative;
  transition: all 0.5s;
  z-index: 1;
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  -ms-transition: all 0.9s;
  -o-transition: all 0.9s;
}
.maindiv:hover .rotate i:before {
  color: #fff;
  transform: rotateY(180deg);
  transition: all 0.5s;
  margin-left: 20px;
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  -ms-transition: all 0.9s;
  -o-transition: all 0.9s;
}
.maindiv .rotate svg {
  margin-top: 40px;
}
.maindiv .rotate svg .icon_svg {
  fill: #3a89ff;
}
.maindiv:hover .rotate svg .icon_svg {
  fill: #fff;
}
.maindiv:hover .rotate_1 i:before {
  color: #fff;
  transform: rotateY(180deg);
  transition: all 0.5s;
  margin-left: 0px;
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  -ms-transition: all 0.9s;
  -o-transition: all 0.9s;
}
.maindiv:hover h4 a {
  transition: all 0.5s;
  color: #3a89ff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.maindiv h4 a {
  font-size: 20px;
  font-weight: 600;
  z-index: 10;
  position: relative;
  text-transform: capitalize;
}
.maindiv:before {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 1.3s;
  transition: opacity 0.35s, transform 1.3s;
  border-right: 1px solid rgb(200 193 193);
  border-left: 1px solid rgb(200 193 193);
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}
img,
video {
  max-width: 100%;
  height: auto;
}
.maindiv:after {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 1.3s;
  transition: opacity 0.5s, transform 1.3s;
  border-top: 1px solid rgb(200 193 193);
  border-bottom: 1px solid rgb(200 193 193);
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
.rotate {
  margin: auto;
  display: block;
  width: 113px;
  height: 113px;
  background-color: #ffffff;
  border-radius: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 26px;
  margin-top: -78px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.our-service {
  background-color: #fff;
}
.flex-col {
  flex-direction: column;
}
.min-h-screen {
  min-height: 100vh;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.btn-sm {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important;
  border-radius: 4px !important;
  padding-bottom: 1rem !important;
}
.btn,
.btn-sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  font-weight: 500;
  line-height: 1.375;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bg-blue {
  background-color: #2563eb;
}
.active-header {
  color: #3a89ff;
}
.ml-6 {
  margin-left: 1.5rem;
}
.flex {
  display: flex;
}
#header {
  padding: 10px 0;
  background-color: #fff;
  width: 100% !important;
  z-index: 9999999;
}
.icons {
  width: 8rem !important;
}
.trust-security {
  /* background-color: #45aee8; */
  /* color: #fff; */
  padding-bottom: 25px;
}
img.call-1 {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}
.reveal-from-bottom {
  margin: 20px;
}
.h-20 {
  height: 5rem;
}
.call-icon {
  display: flex;
  align-items: center;
}
.z-30 {
  background-color: white;
  z-index: 30;
}
.absolute {
  position: absolute;
}
.items-center {
  align-items: center;
}
.shrink-0 {
  flex-shrink: 0;
}
.mr-5 {
  margin-right: 1.25rem;
}
.grow {
  flex-grow: 1;
}
.header-text {
  flex-grow: 0;
  font-family: Gordita Regular !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: left;
  color: rgba(29, 38, 58, 0.8);
}
.h-3 {
  height: 0.75rem;
}
.relative {
  position: relative;
}
.h-35rem {
  height: 35rem;
}
.w-3 {
  width: 0.75rem;
}

.contentlink {
  display: block;
}
.contentlink {
  width: 100%;
  float: left;
}
.contentlink:hover .contentbox .overlay {
  filter: alpha(enabled=false);
  opacity: 1;
}
.contentlink:hover .contentbox .text {
  top: 60px !important;
}
.contentlink:hover .contentbox .span {
  filter: alpha(enabled=false);
  opacity: 1;
  -moz-transition-delay: 0;
  -o-transition-delay: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.contentlink .contentbox {
  display: block;
  width: 100%;
  float: left;
  position: relative;
  background: #eee;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}
.contentlink .contentbox .overlay,
.contentlink .contentbox .text {
  position: absolute;
  left: 0;
  transition: all 0.2s linear;
}
.contentlink .contentbox .overlay {
  filter: alpha(Opacity=0);
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(14, 122, 196, 0.8);
  transition: all 0.2s linear;
}
.contentlink .contentbox .text {
  margin-top: -27px;
  width: 100%;
  z-index: 2;
  text-align: center !important;
  transition: all 0.2s linear;
}
.contentlink .contentbox .span {
  transition: all 0.2s linear;
}
.contentlink .contentbox .text h3 {
  display: inline-block;
  text-align: center !important;
  font-size: 26px;
  font-weight: 800;
  position: relative;
  padding-bottom: 20px;
  margin: 0;
  color: #fff;
}
.contentlink .contentbox .text h3:after {
  content: "";
  width: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -10px;
  height: 1px;
  background: #fff;
}
.contentlink .contentbox .span {
  display: block;
  padding-top: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  filter: alpha(Opacity=0);
  opacity: 0;
  transition: all 0.2s linear;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}
input[type="submit"]:hover {
  background-color: #45a049;
}
.admin-header {
    width: 280px;
    height: 100vh;
    position: fixed;
}